<script>
const fieldTemplateId = 'template_id';
const fieldTemplateName = 'name';
const fieldTemplateDescription = 'description';

const fieldTitleTemplateName = 'Template Name';
const fieldTitleTemplateDescription = 'Template Description';
const buttonAddLabel = 'Add';
const buttonSaveLabel = 'Save';
const messageNoTemplatesText = 'Nothing to show';

const generateRowId = () => (Math.random() + 1).toString(36).substring(7);
</script>

<script setup>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import Button from "primevue/button";
import TrashIcon from "primevue/icons/trash";
import EyeIcon from "primevue/icons/eye";
import PlusIcon from "primevue/icons/plus";
import {computed, ref} from "vue";

const props = defineProps({
    templates: {
        type: Array,
        required: true
    },
    saveUrl: {
        type: String,
    },
    templateFeesUrl: {
        type: String,
    },
    fieldTemplateId: {
        type: String,
        default: fieldTemplateId
    },
    fieldTemplateName: {
        type: String,
        default: fieldTemplateName
    },
    fieldTemplateDescription: {
        type: String,
        default: fieldTemplateDescription
    },
    fieldTitleTemplateName: {
        type: String,
        default: fieldTitleTemplateName
    },
    fieldTitleTemplateDescription: {
        type: String,
        default: fieldTitleTemplateDescription
    },
    buttonAddLabel: {
        type: String,
        default: buttonAddLabel
    },
    buttonSaveLabel: {
        type: String,
        default: buttonSaveLabel
    },
    messageNoTemplatesText: {
        type: String,
        default: messageNoTemplatesText
    },
})

const emit = defineEmits(['save:success', 'save:error']);

const editable = computed(() => !!props.saveUrl);
const rows = ref(
    props.templates.map((template) => ({
        _id: generateRowId(),
        [props.fieldTemplateId]: template[props.fieldTemplateId],
        [props.fieldTemplateName]: template[props.fieldTemplateName],
        [props.fieldTemplateDescription]: template[props.fieldTemplateDescription],
    })));
const editingRows = ref([]);
const unsavedRow = ref({});

function onSave() {
    return fetch(props.saveUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            rows.value.map(
                (row) => {
                    (delete row._id);
                    return row;
                }
            )),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                throw new Error(json.error);
            }
            if (json.success !== true) {
                throw new Error('Unable to store template');
            }
        })
        .then(() => emit('save:success'))
        .catch((e) => emit('save:error', e.message))
}

function onRowAdd() {
    unsavedRow.value = {
        _id: generateRowId(),
        [props.fieldTemplateId]: null,
        [props.fieldTemplateName]: null,
        [props.fieldTemplateDescription]: null,
    };

    rows.value.push(unsavedRow.value);
    editingRows.value = [unsavedRow.value];
}

function onRowDelete(event, row) {
    const index = rows.value.indexOf(row);
    rows.value.splice(index, 1);
    editingRows.value = [];
}

function onTemplateFees(event, row) {
  window.location.href = props.templateFeesUrl.replace('%template_id%', row[props.fieldTemplateId]);
}

function onRowEditInit(event) {
    popUnsavedRow();
    editingRows.value = [event.data];
}

function onRowEditCancel() {
    editingRows.value = [];
}

function onRowEditSave(event) {
    Object.keys(event.data).map((filed) => {
        if (Object.hasOwn(event.newData, filed)) {
            event.data[filed] = event.newData[filed];
        }
    });

    unsavedRow.value = null;
    editingRows.value = [];
}

function popUnsavedRow() {
    if (unsavedRow.value) {
        const index = rows.value.indexOf(unsavedRow.value);
        if (index > -1) {
            rows.value.splice(index, 1);
        }
        unsavedRow.value = null;
    }
}
</script>

<template>
    <div class="vue-processing-fees-templates">
        <div class="row">
            <DataTable
                v-model:editingRows="editingRows"
                :value="rows"
                @row-edit-init="onRowEditInit"
                @row-edit-cancel="onRowEditCancel"
                @row-edit-save="onRowEditSave"
                :editMode="editable ? 'row': undefined"
                dataKey="_id"
                tableClass="table table-striped"
                bodyClass="table-striped"
                tableStyle="min-width: 50rem"
                :pt="{
            table: {
                style: 'min-width: 1280px'
            },
            column: {
                bodyCell: ({ state }) => ({
                    style:  state['d_editing'] && 'padding-top: 10px; padding-bottom: 10px'
                }),
                rowEditorInitButton: {
                     style: 'border: none; background: transparent;'
                },
                rowEditorSaveButton: {
                    style: 'border: none; background: transparent;'
                },
                rowEditorCancelButton: {
                    style: 'border: none; background: transparent;'
                },
            }
        }">

                <template #empty>{{ props.messageNoTemplatesText }}</template>

                <Column :field="props.fieldTemplateName" :header="props.fieldTitleTemplateName" sortable style="width:20%">
                    <template #body="{ data, field }">{{ data[field] }}</template>
                    <template #editor="{ data, field }">
                        <InputText type="text" v-model="data[field]"/>
                    </template>
                </Column>

                <Column :field="props.fieldTemplateDescription" :header="props.fieldTitleTemplateDescription" style="width:80%">
                    <template #body="{ data, field }">{{ data[field] }}</template>
                    <template #editor="{ data, field }">
                        <InputText type="text" v-model="data[field]"/>
                    </template>
                </Column>

                <Column v-if="editable" :row-editor="true" style="width:5%;" bodyStyle="text-align:center"></Column>

                <Column :row-editor="true" :showAddButton="false" style="width:3%" bodyStyle="text-align:center">
                    <template #body="{ data }">
                        <button v-if="data[props.fieldTemplateId]" type="button" aria-label="Processing Fees" @click="(e) => onTemplateFees(e, data)"
                                style="border: none; background: transparent;">
                            <component :is="EyeIcon"/>
                        </button>
                    </template>
                </Column>

                <Column v-if="editable" :row-editor="true" :showAddButton="false" style="width:3%" bodyStyle="text-align:center">
                    <template #body="{ data }">
                        <button type="button" aria-label="Delete row" @click="(e) => onRowDelete(e, data)"
                                style="border: none; background: transparent;">
                            <component :is="TrashIcon"/>
                        </button>
                    </template>
                </Column>

                <template #footer v-if="editable">
                    <div class="pull-left">
                        <Button class="add-row btn btn-default btn-xs"
                                type="button"
                                outlined
                                :key="editingRows"
                                :disabled="editingRows.length > 0"
                                @click="onRowAdd">
                            <component :is="PlusIcon"/>
                            {{ props.buttonAddLabel }}
                        </Button>
                    </div>
                </template>
            </DataTable>
        </div>

        <div v-if="editable" class="row">
            <div class="col-md-12">
                <div class="pull-right">
                    <Button class="btn btn-success btn-fill"
                            type="button"
                            :key="editingRows"
                            :disabled="editingRows.length > 0"
                            :label="props.buttonSaveLabel"
                            outlined @click="onSave"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.vue-processing-fees-templates {
   input[type="text"] {
        width: 90%;
    }

    button.add-row {
        margin-left: 10px;
        font-size: small;

        svg {
            padding-top: 4px;
        }
    }
}
</style>
