import {createApp} from "vue/dist/vue.esm-bundler";
import TosBarComponent from "./tos_bar/bar.vue";
import VirtualTerminalComponent from "./virtualterminal/terminal.vue"
import ProcessingFees from "./processing_fees/ProcessingFees.vue"
import ProcessingFeesTemplates from "./processing_fees/ProcessingFeesTemplates.vue"
import DeclinesStatsComponent from "./declines_stats/DeclinesStats.vue"
import PrimeVue from "primevue/config";

function getCurrencyMinorUnits(currencyCode) {
    const format = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
    });

    const parts = format.formatToParts(123);
    const fraction = parts.find(m => m.type === 'fraction');

    return fraction ? fraction.value.length : 0;
}

function formatMoney(amount, currency) {
    const minorUnits = getCurrencyMinorUnits(currency);
    const amountToFormat = amount / Math.pow(10, minorUnits);

    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        minimumFractionDigits: minorUnits,
        maximumFractionDigits: minorUnits,
    }).format(amountToFormat);
}

function formatMoneyWithoutCurrency(amount, currency) {
    const minorUnits = getCurrencyMinorUnits(currency);
    const amountToFormat = amount / Math.pow(10, minorUnits);

    return new Intl.NumberFormat('en-US', {
        style: 'decimal',
        minimumFractionDigits: minorUnits,
        maximumFractionDigits: minorUnits,
    }).format(amountToFormat);
}

if (!!document.getElementById("tos-bar-vue-app")) {
    createApp({name: "TermsOfServiceBarApp"})
        .component("terms-of-service-bar", TosBarComponent)
        .mount("#tos-bar-vue-app");
}

if (!!document.getElementById("virtual_terminal_app")) {
    createApp({name: "VirtualTerminalApp"})
        .component("virtual-terminal", VirtualTerminalComponent)
        .mount("#virtual_terminal_app");
}

if (!!document.getElementById("vue-processing-fees-app")) {
    const vueApp = createApp({name: "ProcessingFeesApp"});
    vueApp.use(PrimeVue, {unstyled: true})
        .component("processing-fees", ProcessingFees)
        .provide('formatMoneyInMinorUnits', formatMoney)
        .provide('formatMoneyInMinorUnitsWithoutCurrency', formatMoneyWithoutCurrency);

    vueApp.config.globalProperties.window = window;
    vueApp.config.globalProperties.backoffice = app;

    vueApp.mount("#vue-processing-fees-app");
}

if (!!document.getElementById("vue-processing-fees-templates-app")) {
    const vueApp = createApp({name: "ProcessingFeesTemplatesApp"});
    vueApp.use(PrimeVue, {unstyled: true})
        .component("processing-fees-templates", ProcessingFeesTemplates)
        .provide('formatMoneyInMinorUnits', formatMoney)
        .provide('formatMoneyInMinorUnitsWithoutCurrency', formatMoneyWithoutCurrency);

    vueApp.config.globalProperties.window = window;
    vueApp.config.globalProperties.backoffice = app;

    vueApp.mount("#vue-processing-fees-templates-app");
}

if (!!document.getElementById("declines_stats_app")) {
    createApp({name: "DeclinesStatsApp"})
        .component("declines-stats", DeclinesStatsComponent)
        .mount("#declines_stats_app");
}
