<script>
const optionsList = [
    {value: null, label: 'ANY'},
    {value: 'credit', label: 'Credit'},
    {value: 'debit', label: 'Debit'},
    {value: 'commercial', label: 'Commercial'},
    {value: 'unknown', label: 'Unknown'},
];
const walletOptionsList = [
    {value: null, label: 'ANY'},
    {value: 'credit', label: 'Credit'},
    {value: 'debit', label: 'Debit'},
    {value: 'unknown', label: 'Unknown'},
];

const subTypeRequiredTypes = ['card', 'credit_card'];
const subTypeRequiredWalletTypes = ['apple_pay', 'google_pay'];

export function fundingInstrumentSubTypeName(type) {
    const option = optionsList.find((o) => o.value === type);

    return option ? option.label : 'Unknown';
}

</script>

<script setup>

import {computed} from "vue";

const model = defineModel();
const props = defineProps({
    type: {
        type: [String, null],
    },
    enableForWallets: {
        type: Boolean,
        default: false,
    }
});

const options = computed(() => {
    if (subTypeRequiredTypes.includes(props.type)) {
        return optionsList;
    }

    if (subTypeRequiredWalletTypes.includes(props.type) && props.enableForWallets) {
        return walletOptionsList;
    }

    return optionsList.filter((o) => o.value === null);
});
</script>

<template>
    <select v-model="model">
        <option v-for="option in options" :value="option.value">{{ option.label }}</option>
    </select>
</template>
