<template>
    <transition name="fade">
        <div class="tos-bar-container" v-if="show">
            <div class="tos-bar">
                <p>{{ message }} <a :href="tosPage">{{ acceptMessage }}</a></p>
                <div class="pull-right close-btn"><a href="" class="pe-7s-close" title="Close"
                                                     @click.prevent="close"></a></div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: 'TermsOfServiceBar',
    props: {
        tosUri: {type: String},
        tosPage: {type: String},
        message: {type: String},
        acceptMessage: {type: String},
        mode: {type: String},
    },
    data: function () {
        return {
            show: false,
        }
    },
    mounted: function () {
        const self = this;
        fetch(this.tosUri)
            .then(function (res) {
                if (res.ok) {
                    return res.json();
                }

                return Promise.reject(new Error(res.statusText));
            })
            .then(function (res) {
                self.show = (res.are_accepted === false);
            });
    },
    methods: {
        close: function () {
            this.show = false;
        },
    },
}
</script>

<style>
.tos-bar-container {
    margin: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 10rem;
    z-index: 100;
    border-top: solid 3px #ffa500;
}

.tos-bar .close-btn a {
    font-size: 5rem;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
}

.tos-bar-container.fade-enter-active, .tos-bar-container.fade-leave-active {
    transition: opacity 1s
}

.tos-bar-container.fade-enter-from, .tos-bar-container.fade-leave-to {
    opacity: 0;
}

.tos-bar-container .tos-bar {
    padding: 0 50px;
    background: #2d2929;
    color: #fff;
    opacity: 0.9;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tos-bar-container .tos-bar p {
    text-align: center;
    margin: 0;
}

.tos-bar-container .tos-bar p a {
    white-space: nowrap;
}

</style>
