<script>

const options = [
    {value: null, label: 'ANY'},
    {value: 'alipay', label: 'Alipay'},
    {value: 'apple_pay', label: 'ApplePay'},
    {value: 'credit_card', label: 'Card'},
    {value: 'crypto', label: 'Crypto'},
    {value: 'direct_debit', label: 'SEPA Direct Debit'},
    {value: 'eps', label: 'EPS'},
    {value: 'giropay', label: 'Giropay'},
    {value: 'google_pay', label: 'GooglePay'},
    {value: 'ideal', label: 'iDEAL'},
    {value: 'invoice', label: 'Invoice'},
    {value: 'klarna', label: 'Klarna'},
    {value: 'merchant', label: 'Merchant'},
    {value: 'payworks', label: 'Payworks'},
    {value: 'rebate', label: 'Rebate'},
    {value: 'sofort', label: 'SOFORT'},
    {value: 'twint', label: 'Twint'}
];

export function fundingInstrumentTypeName(type) {
    const option = options.find((o) => o.value === type);

    return option ? option.label : 'Unknown';
}

export function isWalletType(type) {
    return ['apple_pay', 'google_pay'].includes(type);
}
</script>

<script setup>

const model = defineModel();

</script>

<template>
    <select v-model="model">
        <option v-for="option in options" :value="option.value">{{ option.label }}</option>
    </select>
</template>
