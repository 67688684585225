<script>
const schemaRequiredTypes = ['card', 'credit_card', 'apple_pay', 'google_pay'];
const optionsList = [
    {value: null, label: 'ANY'},
    {value: 'amex', label: 'Amex'},
    {value: 'diners', label: 'Diners'},
    {value: 'discover', label: 'Discover'},
    {value: 'e-guma', label: 'e-guma'},
    {value: 'invoice', label: 'Invoice'},
    {value: 'jcb', label: 'Jcb'},
    {value: 'maestro', label: 'Maestro'},
    {value: 'mastercard', label: 'Mastercard'},
    {value: 'unionpay', label: 'Unionpay'},
    {value: 'unknown', label: 'Unknown'},
    {value: 'visa', label: 'Visa'},
];

export function fundingInstrumentSchemaName(type) {
    const option = optionsList.find((o) => o.value === type);

    return option ? option.label : 'Unknown';
}
</script>

<script setup>

import {computed} from "vue";

const model = defineModel();
const props = defineProps({
    type: {
        type: [String, null],
    },
});
const options = computed(() => {
    if (schemaRequiredTypes.includes(props.type)) {
        return optionsList;
    }

    return optionsList.filter((o) => o.value === null);
});

</script>

<template>
    <select v-model="model">
        <option v-for="option in options" :value="option.value">{{ option.label }}</option>
    </select>
</template>
