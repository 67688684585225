<template>
    <div class="row">
        <div class="col-sm-3 form-group">
            <label>Date range</label>
            <div class="row">
                <div class="col-sm-6">
                    <input type="date" class="form-control" v-model="fromDate"/>
                </div>
                <div class="col-sm-6">
                    <input type="date" class="form-control" v-model="toDate"/>
                </div>
            </div>
        </div>
        <div class="col-sm-9 form-group">
            <label>Aggregations</label>
            <div>
                <div class="btn-group" role="group">
                    <button :class="['btn', hasAggregation('integrator') ? 'btn-primary btn-fill' : 'btn-default']"
                            @click.prevent="toggleAggregation('integrator')">
                        Integrators
                    </button>
                    <button :class="['btn', hasAggregation('merchant') ? 'btn-primary btn-fill' : 'btn-default']"
                            @click.prevent="toggleAggregation('merchant')">
                        Merchants
                    </button>
                    <button :class="['btn', hasAggregation('payment_method') ? 'btn-primary btn-fill' : 'btn-default']"
                            @click.prevent="toggleAggregation('payment_method')">
                        Payment Methods
                    </button>
                    <button :class="['btn', hasAggregation('acquirer') ? 'btn-primary btn-fill' : 'btn-default']"
                            @click.prevent="toggleAggregation('acquirer')">
                        Acquirers
                    </button>
                    <button :class="['btn', hasAggregation('issuer_country') ? 'btn-primary btn-fill' : 'btn-default']"
                            @click.prevent="toggleAggregation('issuer_country')">
                        Issuer Country
                    </button>
                    <button :class="['btn', hasAggregation('issuer_name') ? 'btn-primary btn-fill' : 'btn-default']"
                            @click.prevent="toggleAggregation('issuer_name')">
                        Issuer
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-2 form-group">
            <label>
                Integrator
                <a v-if="hasFilter('integrator_id')" href="#" @click.prevent="setFilter('integrator_id', null)">
                    <i class="fa fa-fw fa-times"></i>
                </a>
            </label>
            <p v-if="hasFilter('integrator_id')" class="form-control-static">{{ integrators[getFilter('integrator_id')] || getFilter('integrator_id') }}</p>
            <select v-else class="form-control" @change="e => setFilter('integrator_id', e.target.value)">
                <option value=""> --- None ---</option>
                <option v-for="(name, integratorId) in integrators" :value="integratorId">{{ name }}</option>
            </select>
        </div>
        <div class="col-sm-2 form-group">
            <label>
                Merchant
                <a v-if="hasFilter('merchant_id')" href="#" @click.prevent="setFilter('merchant_id', null)">
                    <i class="fa fa-fw fa-times"></i>
                </a>
            </label>
            <p v-if="hasFilter('merchant_id')" class="form-control-static">{{ getFilter('merchant_id') }}</p>
            <input v-else type="text" class="form-control" placeholder="Merchant ID" @keyup.enter="e => setFilter('merchant_id', e.target.value)"/>
        </div>
        <div class="col-sm-2 form-group">
            <label>
                Payment method
                <a v-if="hasFilter('payment_method')" href="#" @click.prevent="setFilter('payment_method', null)">
                    <i class="fa fa-fw fa-times"></i>
                </a>
            </label>
            <p v-if="hasFilter('payment_method')" class="form-control-static">{{ paymentMethods[getFilter('payment_method')] || getFilter('payment_method') }}</p>
            <select v-else class="form-control" @change="e => setFilter('payment_method', e.target.value)">
                <option value=""> --- None ---</option>
                <option v-for="(name, paymentMethod) in paymentMethods" :value="paymentMethod">{{ name }}</option>
            </select>
        </div>
        <div class="col-sm-2 form-group">
            <label>
                Acquirer
                <a v-if="hasFilter('acquirer_id')" href="#" @click.prevent="setFilter('acquirer_id', null)">
                    <i class="fa fa-fw fa-times"></i>
                </a>
            </label>
            <p v-if="hasFilter('acquirer_id')" class="form-control-static">{{ acquirerNames[getFilter('acquirer_id')] || getFilter('acquirer_id') }}</p>
            <input v-else type="text" class="form-control" placeholder="Acquirer ID" @keyup.enter="e => setFilter('acquirer_id', e.target.value)"/>
        </div>
        <div class="col-sm-2 form-group">
            <label>
                Issuer Country
                <a v-if="hasFilter('issuer_country')" href="#" @click.prevent="setFilter('issuer_country', null)">
                    <i class="fa fa-fw fa-times"></i>
                </a>
            </label>
            <p v-if="hasFilter('issuer_country')" class="form-control-static">{{ getFilter('issuer_country') }}</p>
            <input v-else type="text" class="form-control" placeholder="Issuer Country" @keyup.enter="e => setFilter('issuer_country', e.target.value)"/>
        </div>
        <div class="col-sm-2 form-group">
            <label>
                Issuer
                <a v-if="hasFilter('issuer_name')" href="#" @click.prevent="setFilter('issuer_name', null)">
                    <i class="fa fa-fw fa-times"></i>
                </a>
            </label>
            <p v-if="hasFilter('issuer_name')" class="form-control-static">{{ getFilter('issuer_name') }}</p>
            <input v-else type="text" class="form-control" placeholder="Issuer" @keyup.enter="e => setFilter('issuer_name', e.target.value)"/>
        </div>
    </div>

    <div v-if="sortedStats" class="table-responsive">
        <table class="table table-hover table-bordered table-sticky">
            <thead>
            <tr>
                <th class="white" v-if="hasAggregation('integrator')" rowspan="2">
                    Integrator
                    <i :class="['pointer fa fa-fw', sortAsc ? 'fa-sort-alpha-asc' : 'fa-sort-alpha-desc']" @click="sortStats('integrator_id')"></i>
                </th>
                <th class="white" v-if="hasAggregation('merchant')" rowspan="2">
                    Merchant
                    <i :class="['pointer fa fa-fw', sortAsc ? 'fa-sort-numeric-asc' : 'fa-sort-numeric-desc']" @click="sortStats('merchant_id')"></i>
                </th>
                <th class="white" v-if="hasAggregation('payment_method')" rowspan="2">
                    Payment Method
                    <i :class="['pointer fa fa-fw', sortAsc ? 'fa-sort-alpha-asc' : 'fa-sort-alpha-desc']" @click="sortStats('payment_method')"></i>
                </th>
                <th class="white" v-if="hasAggregation('acquirer')" rowspan="2">
                    Acquirer
                    <i :class="['pointer fa fa-fw', sortAsc ? 'fa-sort-alpha-asc' : 'fa-sort-alpha-desc']" @click="sortStats('acquirer_id')"></i>
                </th>
                <th class="white" v-if="hasAggregation('issuer_country')" rowspan="2">
                    Issuer Country
                    <i :class="['pointer fa fa-fw', sortAsc ? 'fa-sort-alpha-asc' : 'fa-sort-alpha-desc']" @click="sortStats('issuer_country')"></i>
                </th>
                <th class="white" v-if="hasAggregation('issuer_name')" rowspan="2">
                    Issuer
                    <i :class="['pointer fa fa-fw', sortAsc ? 'fa-sort-alpha-asc' : 'fa-sort-alpha-desc']" @click="sortStats('issuer_name')"></i>
                </th>
                <th colspan="2" class="text-center info">Total</th>
                <th colspan="2" class="text-center success">Successful</th>
                <th colspan="2" class="text-center warning">Eventually Successful</th>
                <th colspan="2" class="text-center danger">Unsuccessful</th>
            </tr>
            <tr>
                <th class="text-right info">
                    # Attempts
                    <i :class="['pointer fa fa-fw', sortAsc ? 'fa-sort-numeric-asc' : 'fa-sort-numeric-desc']" @click="sortStats('total')"></i>
                </th>
                <th class="text-right info">% of Total Attempts</th>
                <th class="text-right success">
                    # Attempts
                    <i :class="['pointer fa fa-fw', sortAsc ? 'fa-sort-numeric-asc' : 'fa-sort-numeric-desc']" @click="sortStats('successful')"></i>
                </th>
                <th class="text-right success">
                    % of Total Attempts
                    <i :class="['pointer fa fa-fw', sortAsc ? 'fa-sort-numeric-asc' : 'fa-sort-numeric-desc']" @click="sortStats('successful_rate')"></i>
                </th>
                <th class="text-right warning">
                    # Attempts
                    <i :class="['pointer fa fa-fw', sortAsc ? 'fa-sort-numeric-asc' : 'fa-sort-numeric-desc']" @click="sortStats('eventually_successful')"></i>
                </th>
                <th class="text-right warning">
                    % of Total Attempts
                    <i :class="['pointer fa fa-fw', sortAsc ? 'fa-sort-numeric-asc' : 'fa-sort-numeric-desc']" @click="sortStats('eventually_successful_rate')"></i>
                </th>
                <th class="text-right danger">
                    # Attempts
                    <i :class="['pointer fa fa-fw', sortAsc ? 'fa-sort-numeric-asc' : 'fa-sort-numeric-desc']" @click="sortStats('unsuccessful')"></i>
                </th>
                <th class="text-right danger">
                    % of Total Attempts
                    <i :class="['pointer fa fa-fw', sortAsc ? 'fa-sort-numeric-asc' : 'fa-sort-numeric-desc']" @click="sortStats('unsuccessful_rate')"></i>
                </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in sortedStats.items">
                <td v-if="hasAggregation('integrator')">
                    <a href="#" @click.prevent="setFilter('integrator_id', item.integrator_id)">
                        <i class="fa fa-fw fa-filter"></i>
                    </a>
                    {{ integrators[item.integrator_id] || item.integrator_id }}
                </td>
                <td v-if="hasAggregation('merchant')">
                    <a href="#" @click.prevent="setFilter('merchant_id', item.merchant_id)">
                        <i class="fa fa-fw fa-filter"></i>
                    </a>
                    {{ item.merchant_id }}
                </td>
                <td v-if="hasAggregation('payment_method')">
                    <a href="#" @click.prevent="setFilter('payment_method', item.payment_method)">
                        <i class="fa fa-fw fa-filter"></i>
                    </a>
                    {{ paymentMethods[item.payment_method] || item.payment_method }}
                </td>
                <td v-if="hasAggregation('acquirer')">
                    <a href="#" @click.prevent="setFilter('acquirer_id', item.acquirer_id)">
                        <i class="fa fa-fw fa-filter"></i>
                    </a>
                    {{ acquirerNames[item.acquirer_id] }}
                </td>
                <td v-if="hasAggregation('issuer_country')">
                    <a href="#" @click.prevent="setFilter('issuer_country', item.issuer_country)">
                        <i class="fa fa-fw fa-filter"></i>
                    </a>
                    {{ item.issuer_country }}
                </td>
                <td v-if="hasAggregation('issuer_name')">
                    <a href="#" @click.prevent="setFilter('issuer_name', item.issuer_name)">
                        <i class="fa fa-fw fa-filter"></i>
                    </a>
                    {{ item.issuer_name }}
                </td>
                <td class="text-right info">{{ item.total }}</td>
                <td class="text-right info">100.00%</td>
                <td class="text-right success">{{ item.successful }}</td>
                <td class="text-right success">{{ item.successful_rate.toFixed(2) }}%</td>
                <td class="text-right warning">{{ item.eventually_successful }}</td>
                <td class="text-right warning">{{ item.eventually_successful_rate.toFixed(2) }}%</td>
                <td class="text-right danger">{{ item.unsuccessful }}</td>
                <td class="text-right danger">{{ item.unsuccessful_rate.toFixed(2) }}%</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <th class="white" v-if="hasAggregation('integrator')">Total</th>
                <th class="white" v-if="hasAggregation('merchant')">Total</th>
                <th class="white" v-if="hasAggregation('payment_method')"></th>
                <th class="white" v-if="hasAggregation('acquirer')"></th>
                <th class="white" v-if="hasAggregation('issuer_country')"></th>
                <th class="white" v-if="hasAggregation('issuer_name')"></th>
                <td class="text-right info">{{ stats.totals.total }}</td>
                <td class="text-right info">100.00%</td>
                <td class="text-right success">{{ stats.totals.successful }}</td>
                <td class="text-right success">{{ stats.totals.successful_rate.toFixed(2) }}%</td>
                <td class="text-right warning">{{ stats.totals.eventually_successful }}</td>
                <td class="text-right warning">{{ stats.totals.eventually_successful_rate.toFixed(2) }}%</td>
                <td class="text-right danger">{{ stats.totals.unsuccessful }}</td>
                <td class="text-right danger">{{ stats.totals.unsuccessful_rate.toFixed(2) }}%</td>
            </tr>
            </tfoot>
        </table>
    </div>
</template>

<script setup>
import { computed, reactive, ref, watch } from "vue";

const props = defineProps({
    dataUrl: {
        type: String,
        required: true
    },
    integratorsJson: {
        type: String,
        require: true,
    },
    paymentMethodsJson: {
        type: String,
        require: true,
    },
    fromDate: {
        type: String,
        require: true,
    },
    toDate: {
        type: String,
        require: true,
    },
});

const integrators = JSON.parse(props.integratorsJson);
const paymentMethods = JSON.parse(props.paymentMethodsJson);
const acquirerNames = {
    worldline: 'Worldline',
    stripe: 'Stripe',
    payyo: 'Payyo',
    swissbilling: 'SwissBilling',
    coinbase: 'Coinbase',
    postfinance: 'PostFinance',
}

const fromDate = ref(props.fromDate);
const toDate = ref(props.toDate);

const aggregationStatuses = reactive({
    'integrator': false,
    'merchant': false,
    'payment_method': false,
    'acquirer': false,
    'issuer_country': false,
    'issuer_name': false,
});

function hasAggregation(aggregation) {
    return aggregationStatuses[aggregation] === true;
}

function toggleAggregation(aggregation) {
    aggregationStatuses[aggregation] = !aggregationStatuses[aggregation];
}

const filters = reactive({});

function hasFilter(filter) {
    return filters[filter] !== undefined;
}

function getFilter(filter) {
    return filters[filter];
}

function setFilter(filter, value) {
    if (value) {
        filters[filter] = value;
    } else {
        delete filters[filter];
    }
}

const stats = ref(null);

function calculateTotals(items) {
    const total = {
        total: 0,
        successful: 0,
        eventually_successful: 0,
        unsuccessful: 0,
    };

    for (const item of items) {
        total.total += item.total;
        total.successful += item.successful;
        total.eventually_successful += item.eventually_successful;
        total.unsuccessful += item.unsuccessful;
    }

    if (total.total > 0) {
        total.successful_rate = total.successful / total.total * 100;
        total.eventually_successful_rate = total.eventually_successful / total.total * 100;
        total.unsuccessful_rate = total.unsuccessful / total.total * 100;
    } else {
        total.successful_rate = total.eventually_successful_rate = total.unsuccessful_rate = 0.0;
    }

    return total;
}

const sortBy = ref('unsuccessful_rate');
const sortAsc = ref(true);

function sortStats(column) {
    sortBy.value = column;
    sortAsc.value = !sortAsc.value;
}

const sortedStats = computed(() => {
    if (!stats.value) {
        return null;
    }

    return {
        totals: stats.value.totals,
        items: stats.value.items.sort((a, b) => {
            let valA = a[sortBy.value];
            let valB = b[sortBy.value];
            let isString = false;

            if (sortBy.value === 'integrator_id') {
                valA = integrators[valA] || valA;
                valB = integrators[valB] || valB;
                isString = true;
            } else if (sortBy.value === 'payment_method') {
                valA = paymentMethods[valA] || valA;
                valB = paymentMethods[valB] || valB;
                isString = true;
            } else if (sortBy.value === 'acquirer_id') {
                valA = acquirerNames[valA] || valA;
                valB = acquirerNames[valB] || valB;
                isString = true;
            }

            if (sortAsc.value) {
                if (isString) {
                    return valA.localeCompare(valB);
                }
                return valB - valA;
            }

            if (isString) {
                return valB.localeCompare(valA);
            }
            return valA - valB;
        })
    };
});

function load() {
    stats.value = null;
    sortBy.value = 'unsuccessful_rate';
    sortAsc.value = true;

    app.callAjax({
        type: 'get',
        url: props.dataUrl,
        data: {
            from: fromDate.value,
            to: toDate.value,
            aggregations: Object.keys(aggregationStatuses).filter(status => aggregationStatuses[status]),
            filters: filters,
        },
        success: items => {
            stats.value = {
                items,
                totals: calculateTotals(items),
            };
        },
        complete: () => {
            app.hideLoadingOverlay();
        },
    });
}

load();
watch([fromDate, toDate, aggregationStatuses, filters], () => load());
</script>

<style scoped>
.table-sticky thead {
    position          : sticky;
    inset-block-start : 0;
    z-index           : 100;
}

.table-sticky thead th.white {
    background-color : #fff;
}

.table-sticky tfoot {
    position        : sticky;
    inset-block-end : 0;
    z-index         : 99;
}

.table-sticky tfoot td.white {
    background-color : #fff;
}

tfoot td {
    font-weight    : bold;
    text-transform : uppercase;
}

.pointer {
    cursor : pointer;
}
</style>
