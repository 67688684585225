<script>
const fieldFundingInstrumentType = 'funding_instrument_type';
const fieldFundingInstrumentSubType = 'funding_instrument_sub_type';
const fieldFundingInstrumentSchemaType = 'funding_instrument_scheme_type';
const fieldRegionId = 'region_id';
const fieldCommissionRate = 'commission_rate';
const fieldFeeAmount = 'transaction_fee_amount';
const fieldFeeCurrency = 'transaction_fee_currency';

const fieldTitleFundingInstrumentType = 'Funding Instrument Type';
const fieldTitleFundingInstrumentSubType = 'Funding Instrument Sub-Type';
const fieldTitleFundingInstrumentSchemaType = 'Funding Instrument Schema Type';
const fieldTitleRegion = 'Region';
const fieldTitleCommission = 'Commission';
const fieldTitleTransactionFee = 'Transaction Fee';

const generateRowId = () => (Math.random() + 1).toString(36).substring(7);
</script>

<script setup>
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputText from 'primevue/inputtext';
import CurrencyList from './CurrencyList.vue';
import RegionList, {regionName} from './RegionList.vue';
import FundingInstrumentType, {fundingInstrumentTypeName, isWalletType} from './FundingInstrumentType.vue';
import FundingInstrumentSubType, {fundingInstrumentSubTypeName} from './FundingInstrumentSubType.vue';
import FundingInstrumentSchema, {fundingInstrumentSchemaName} from "./FundingInstrumentSchema.vue";
import Button from "primevue/button";
import TrashIcon from "primevue/icons/trash";
import PlusIcon from "primevue/icons/plus";
import {computed, inject, ref} from "vue";

const props = defineProps({
    addButtonLabel: {
        type: String,
        default: 'Add'
    },
    saveButtonLabel: {
        type: String,
        default: 'Save'
    },
    fees: {
        type: Array,
        required: true
    },
    saveUrl: {
        type: String,
    },
    editMode: {
        type: String,
        default: 'row'
    },
    enableSubtypesForWallets: {
        type: Boolean,
        default: false,
    },
    currencies: {
        type: Array,
        default: [
            'CHF',
            'EUR',
            'GBP',
            'USD',
        ]
    },
    defaultCurrency: {
        type: String,
        default: 'CHF'
    },
    regions: {
        type: Array,
        default: []
    },
    fieldFundingInstrumentType: {
        type: String,
        default: fieldFundingInstrumentType
    },
    fieldFundingInstrumentSubType: {
        type: String,
        default: fieldFundingInstrumentSubType
    },
    fieldFundingInstrumentSchemaType: {
        type: String,
        default: fieldFundingInstrumentSchemaType
    },
    fieldRegionId: {
        type: String,
        default: fieldRegionId
    },
    fieldCommissionRate: {
        type: String,
        default: fieldCommissionRate
    },
    fieldTransactionFeeAmount: {
        type: String,
        default: fieldFeeAmount
    },
    fieldTransactionFeeCurrency: {
        type: String,
        default: fieldFeeCurrency
    },
    fieldTitleFundingInstrumentType: {
        type: String,
        default: fieldTitleFundingInstrumentType
    },
    fieldTitleFundingInstrumentSubType: {
        type: String,
        default: fieldTitleFundingInstrumentSubType
    },
    fieldTitleFundingInstrumentSchemeType: {
        type: String,
        default: fieldTitleFundingInstrumentSchemaType
    },
    fieldTitleRegion: {
        type: String,
        default: fieldTitleRegion
    },
    fieldTitleCommission: {
        type: String,
        default: fieldTitleCommission
    },
    fieldTitleTransactionFee: {
        type: String,
        default: fieldTitleTransactionFee
    },
})

const emit = defineEmits(['save:success', 'save:error']);
const formatMoney = inject('formatMoneyInMinorUnits', (amount, currency) => '$$$');

const editable = computed(() => !!props.saveUrl);

const rows = ref(
    props.fees.map((fee) => ({
        _id: generateRowId(),
        [props.fieldFundingInstrumentType]: fee[props.fieldFundingInstrumentType],
        [props.fieldFundingInstrumentSubType]: fee[props.fieldFundingInstrumentSubType],
        [props.fieldFundingInstrumentSchemaType]: fee[props.fieldFundingInstrumentSchemaType],
        [props.fieldRegionId]: fee[props.fieldRegionId],
        [props.fieldTransactionFeeAmount]: fee[props.fieldTransactionFeeAmount],
        [props.fieldTransactionFeeCurrency]: fee[props.fieldTransactionFeeCurrency],
        [props.fieldCommissionRate]: fee[props.fieldCommissionRate],
    })));
const editingRows = ref([]);
const unsavedRow = ref({});

function onSave() {
    document.getElementById("saveButtonContainer").click();

    return fetch(props.saveUrl, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(
            rows.value.map(
                (row) => {
                    (delete row._id);
                    return row;
                }
            )),
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.error) {
                throw new Error(json.error);
            }
            if (json.success !== true) {
                throw new Error('Unable to store processing fees');
            }
        })
        .then(() => emit('save:success'))
        .catch((e) => emit('save:error', e.message))
}

function onRowAdd() {
    unsavedRow.value = {
        _id: generateRowId(),
        [props.fieldFundingInstrumentType]: null,
        [props.fieldFundingInstrumentSubType]: null,
        [props.fieldFundingInstrumentSchemaType]: null,
        [props.fieldRegionId]: null,
        [props.fieldTransactionFeeAmount]: 0,
        [props.fieldTransactionFeeCurrency]: props.defaultCurrency,
        [props.fieldCommissionRate]: "0.0",
    };

    rows.value.push(unsavedRow.value);

    if (props.editMode !== 'cell') {
        editingRows.value = [unsavedRow.value];
    }
}

function onRowDelete(event, row) {
    const index = rows.value.indexOf(row);
    rows.value.splice(index, 1);
    editingRows.value = [];
}

function onRowEditInit(event) {
    popUnsavedRow();
    editingRows.value = [event.data];
}

function onRowEditCancel() {
    editingRows.value = [];
}

function onRowEditSave(event) {
    Object.keys(event.data).map((filed) => {
        if (Object.hasOwn(event.newData, filed)) {
            event.data[filed] = event.newData[filed];
        }
    });

    unsavedRow.value = null;
    editingRows.value = [];
}

function onCellEditComplete(event) {
    let { data, newValue, field } = event;
    data[field] = newValue;
    editingRows.value = [];
}

function popUnsavedRow() {
    if (unsavedRow.value) {
        const index = rows.value.indexOf(unsavedRow.value);
        if (index > -1) {
            rows.value.splice(index, 1);
        }
        unsavedRow.value = null;
    }
}

function onFundingInstrumentTypeChange(event, row) {
    // reset related fields
    row[props.fieldFundingInstrumentSubType] = null;
    row[props.fieldFundingInstrumentSchemaType] = null;
    row[props.fieldRegionId] = null;

    // in cell mode, we need to update saved row as well
    if (props.editMode === 'cell') {
        let rowId = row['_id']
        rows.value.map(
            (row) => {
                if (row['_id'] === rowId) {
                    row[props.fieldFundingInstrumentSubType] = null;
                    row[props.fieldFundingInstrumentSchemaType] = null;
                    row[props.fieldRegionId] = null;
                }
                return row;
            }
        )
    }
}
</script>

<template>
    <div class="vue-processing-fees">
        <div class="row">
            <DataTable
                v-model:editingRows="editingRows"
                :value="rows"
                @row-edit-init="onRowEditInit"
                @row-edit-cancel="onRowEditCancel"
                @row-edit-save="onRowEditSave"
                @cell-edit-complete="onCellEditComplete"
                :editMode="editable ? props.editMode : undefined"
                dataKey="_id"
                tableClass="table table-striped"
                bodyClass="table-striped"
                tableStyle="min-width: 50rem"
                :pt="{
            table: {
                style: 'min-width: 1280px'
            },
            column: {
                bodyCell: ({ state }) => ({
                    style:  state['d_editing'] && 'padding-top: 10px; padding-bottom: 10px'
                }),
                rowEditorInitButton: {
                     style: 'border: none; background: transparent;'
                },
                rowEditorSaveButton: {
                    style: 'border: none; background: transparent;'
                },
                rowEditorCancelButton: {
                    style: 'border: none; background: transparent;'
                },
            }
        }">

                <template #empty>No fees defined</template>

                <Column :field="props.fieldFundingInstrumentType" :header="props.fieldTitleFundingInstrumentType" sortable style="width:17%">
                    <template #body="{ data, field }">{{ fundingInstrumentTypeName(data[field]) }}</template>
                    <template #editor="{ data, field }">
                        <FundingInstrumentType v-model="data[field]"
                                               @change="(e) => onFundingInstrumentTypeChange(e, data)"/>
                    </template>
                </Column>

                <Column :field="props.fieldFundingInstrumentSubType" :header="props.fieldTitleFundingInstrumentSubType" sortable
                        style="width:17%">
                    <template #body="{ data, field }">
                        {{ fundingInstrumentSubTypeName(data[field]) }}
                        <a href=""
                           class="fi-subtype-tooltip"
                           @click.prevent
                           v-if="data[field] && isWalletType(data[props.fieldFundingInstrumentType])"
                           title="Setting is applied to Stripe merchants only."><i class="pe-7s-info"></i></a>
                    </template>
                    <template #editor="{ data, field }">
                        <FundingInstrumentSubType v-model="data[field]"
                                                  :type="data[props.fieldFundingInstrumentType]"
                                                  :enable-for-wallets="props.enableSubtypesForWallets"/>
                        <a href=""
                           class="fi-subtype-tooltip"
                           @click.prevent
                           v-if="data[field] && isWalletType(data[props.fieldFundingInstrumentType])"
                           title="Setting is applied to Stripe merchants only."><i class="pe-7s-info"></i></a>
                    </template>
                </Column>

                <Column :field="props.fieldFundingInstrumentSchemaType" :header="props.fieldTitleFundingInstrumentSchemeType" sortable
                        style="width:20%">
                    <template #body="{ data, field }">{{ fundingInstrumentSchemaName(data[field]) }}</template>
                    <template #editor="{ data, field }">
                        <FundingInstrumentSchema v-model="data[field]" :type="data[props.fieldFundingInstrumentType]"/>
                    </template>
                </Column>

                <Column :field="props.fieldRegionId" :header="props.fieldTitleRegion" sortable style="width:13%">
                    <template #body="{ data, field }">{{ regionName(data[field]) }}</template>
                    <template #editor="{ data, field }">
                        <RegionList v-model="data[field]" :regions="props.regions"
                                    :type="data[props.fieldFundingInstrumentType]"/>
                    </template>
                </Column>

                <Column :field="props.fieldTransactionFeeAmount" :header="props.fieldTitleTransactionFee" style="width:15%">
                    <template #body="{ data, field }">
                        {{ formatMoney(data[field], data[props.fieldTransactionFeeCurrency]) }}
                    </template>
                    <template #editor="{ data, field }">
                        <CurrencyList class="currency"
                                      v-model="data[props.fieldTransactionFeeCurrency]"
                                      :currencies="currencies"/>
                        <InputText v-model="data[field]"/>
                    </template>
                </Column>

                <Column :field="props.fieldCommissionRate" :header="props.fieldTitleCommission" style="width:10%">
                    <template #body="{ data, field }">{{ data[field] }}%</template>
                    <template #editor="{ data, field }">
                        <InputText v-model="data[field]"/>
                    </template>
                </Column>

                <Column v-if="editable" :row-editor="true" style="width:5%;" bodyStyle="text-align:center"></Column>

                <Column v-if="editable" :row-editor="true" :showAddButton="false" style="width:3%" bodyStyle="text-align:center">
                    <template #body="{ data }">
                        <button type="button" aria-label="Delete row" @click="(e) => onRowDelete(e, data)"
                                style="border: none; background: transparent;">
                            <component :is="TrashIcon"/>
                        </button>
                    </template>
                </Column>

                <template #footer v-if="editable">
                    <div class="pull-left">
                        <Button class="add-row btn btn-default btn-xs"
                                type="button"
                                outlined
                                :key="editingRows"
                                :disabled="editingRows.length > 0"
                                @click="onRowAdd">
                            <component :is="PlusIcon"/>
                            {{ addButtonLabel }}
                        </Button>
                    </div>
                </template>
            </DataTable>
        </div>

        <div v-if="editable" class="row">
            <div class="col-md-12" id="saveButtonContainer">
                <div class="pull-right">
                    <Button class="btn btn-success btn-fill"
                            type="button"
                            :key="editingRows"
                            :disabled="editingRows.length > 0"
                            :label="saveButtonLabel"
                            outlined @click="onSave"/>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
.vue-processing-fees {
    select {
        width: 140px;
    }

    input[type="text"], input[type="number"] {
        width: 100px;
    }

    select.currency {
        width: 50px;
        margin-right: 5px;
    }

    button.add-row {
        margin-left: 10px;
        font-size: small;

        svg {
            padding-top: 4px;
        }
    }

    a.fi-subtype-tooltip {
        margin-left: 3px;

        i[class^="pe-7s-"] {
            font-size: 12pt;
            vertical-align: text-bottom;
        }
    }
}
</style>
