<template>
    <form @submit.prevent="submit">
        <div class="row form-group">
            <label class="col-sm-2 col-form-label required">Name on Card</label>
            <div class="col-sm-3">
                <input type="text" class="form-control" required v-model="holderName" placeholder="John Doe"/>
            </div>
            <div class="col-sm-3">
                <div class="help-block">
                    <p>The card holder's name as shown on the card.</p>
                </div>
            </div>
        </div>

        <div class="row form-group">
            <label class="col-sm-2 col-form-label required">Card Number</label>
            <div class="col-sm-3">
                <div id="number_container"/>
                <div class="alert alert-danger" v-if="isNumberValid === false">
                    Please verify the card number is correct
                </div>
                <!-- <div class="col-sm-1" v-if="scheme">
                    {{ scheme }}
                </div> -->
            </div>
            <div class="col-sm-3">
                <div class="help-block">
                    <p>The card number as show on the front of the payment card</p>
                </div>
            </div>
        </div>

        <div class="row form-group">
            <label class="col-sm-2 col-form-label required">CVC</label>
            <div class="col-sm-3">
                <div id="cvc_container"/>
                <div class="alert alert-danger" v-if="isCvcValid === false">
                    Please verify the 3 or 4 digit card security code is correct
                </div>
            </div>
            <div class="col-sm-3">
                <div class="help-block">
                    <p>The 3 or 4 digit card security code as show on the back of the payment card</p>
                </div>
            </div>
        </div>

        <div class="row form-group">
            <label class="col-sm-2 col-form-label required">Expiry</label>
            <div class="col-sm-3">
                <select id="expiry_month" class="form-control" v-model.number="expiryMonth">
                    <option value="1">1 - January</option>
                    <option value="2">2 - February</option>
                    <option value="3">3 - March</option>
                    <option value="4">4 - April</option>
                    <option value="5">5 - May</option>
                    <option value="6">6 - June</option>
                    <option value="7">7 - July</option>
                    <option value="8">8 - August</option>
                    <option value="9">9 - September</option>
                    <option value="10">10 - October</option>
                    <option value="11">11 - November</option>
                    <option value="12">12 - December</option>
                </select> / <select id="expiry_year" class="form-control" v-model.number="expiryYear">
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
                <option value="2028">2028</option>
                <option value="2029">2029</option>
                <option value="2030">2030</option>
                <option value="2031">2031</option>
            </select>
            </div>
            <div class="col-sm-3">
                <div class="help-block">
                    <p>The card expiration date as shown on the back of the card.</p>
                </div>
            </div>
        </div>

        <div class="row form-group">
            <label class="col-sm-2 col-form-label required">Merchant Reference</label>
            <div class="col-sm-3">
                <input type="text" class="form-control" required v-model="merchantReference" placeholder="Booking reference" pattern="^[a-zA-Z0-9_-]+$"/>
            </div>
            <div class="col-sm-3">
                <div class="help-block">
                    <p>We recommend to use the booking or order ID from your booking software as the reference.</p>
                    <p>Only letters (<code>a-z</code>, <code>A-Z</code>), numbers (<code>0-9</code>), underscores (<code>_</code>) and hyphens (<code>-</code>) are allowed.</p>
                </div>
            </div>
        </div>

        <div class="row form-group">
            <label class="col-sm-2 col-form-label">Description</label>
            <div class="col-sm-3">
                <input type="text" class="form-control" v-model="description" placeholder="Optional description of the payment ..."/>
            </div>
            <div class="col-sm-3">
                <div class="help-block">
                    <p>An optional description for this payment. The description is free text and will be shown on the payout statement.</p>
                </div>
            </div>
        </div>

        <div class="row form-group">
            <label class="col-sm-2 col-form-label required">Amount</label>
            <div class="col-sm-3">
                <select id="currency" class="form-control" v-model="currency">
                    <option v-for="currency in currencies" :key="currency" :value="currency">{{ currency }}</option>
                </select>
                <input id="amount" type="number" class="form-control" required v-model="amount" placeholder="19.95" min="0" step="0.01"/>
                <span>{{ amountPreview }}</span>
            </div>
            <div class="col-sm-3">
                <div class="help-block">
                    <p>
                        The amount to charge on the card. The payment will be processed via a MOTO (Mail Order/Telephone Order) terminal
                        and payer authentications like 3DS (3D Secure) will be skipped.
                    </p>
                </div>
            </div>
        </div>

        <div class="row form-group">
            <div class="col-sm-2"/>
            <div class="col-sm-6">
                <div v-if="error" class="panel panel-danger">
                    <div class="panel-heading">Something went wrong</div>
                    <div class="panel-body">
                        <p>Error: <strong>{{ error }}</strong></p>
                        <p>Please verify that all inputs are correct and/or try again.</p>
                    </div>
                </div>

                <div v-if="transactionId" class="panel panel-success">
                    <div class="panel-heading">Payment was successful!</div>
                    <div class="panel-body">
                        <p>
                            <code class="transaction-id">{{ transactionId }}</code>
                            <button type="button" class="btn btn-sm btn-default" @click="copyTransactionId">copy</button>
                            <span v-if="copiedTransactionId">✔️</span>
                        </p>
                        <p>
                            <a :href="'/transactions/' + transactionId" class="btn btn-default btn-sm btn-fill" target="_blank">
                                <i class="fa fa-info text-info"></i> Open transaction details
                            </a>
                            or
                            <a :href="transactionReceiptUrl" class="btn btn-default btn-sm btn-fill" target="_blank">
                                <i class="fa fa-print text-info"></i> Show transaction receipt
                            </a>
                            or
                            <a href="#" @click.prevent="newPayment" class="btn btn-default btn-sm btn-fill" target="_blank">
                                <i class="fa fa-credit-card text-info"></i> Create new payment
                            </a>
                        </p>
                    </div>
                </div>
                <div v-else>
                    <button type="submit" :disabled="isLoading" class="btn btn-success btn-fill">Authorize Payment</button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import PtpForm from "@payyo/ptp-js";

export default {
    name: 'VirtualTerminal',
    props: {
        ptpMode: {
            type: String,
            required: true,
            enum: ['live', 'test'],
        },
        ptpFieldUri: {
            type: String,
        },
        currencies: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            isLoading: false,
            currency: this.currencies[0],
            amount: '10.00',
            merchantReference: '',
            description: '',
            holderName: '',
            scheme: '',
            isNumberValid: null,
            isCvcValid: null,
            expiryMonth: 1,
            expiryYear: new Date().getFullYear() + 1,
            error: '',
            transactionId: '',
            transactionReceiptUrl: '',
            copiedTransactionId: false,
        };
    },
    mounted() {
        const inputStyles = {
            'background-color': '#FFFFFF',
            color: '#565656',
            border: '1px solid #E3E3E3',
            'border-radius': '4px',
            padding: '8px 12px',
            height: '40px',
            'box-shadow': 'none',
            outline: 0,
            'font-size': '14px',
            'font-family': '"Roboto","Helvetica Neue",Arial,sans-serif',
        };

        const inputPlaceholderStyles = {
            color: '#DDDDDD',
            opacity: 1,
        };

        const form = PtpForm({ mode: this.ptpMode, fieldUrl: this.ptpFieldUri, debug: false });

        form.onReady(() => {
            this.form.setStyle('cardNumber', '#input-field', inputStyles)
            this.form.setStyle('cardNumber', '#input-field::placeholder', inputPlaceholderStyles)
            this.form.setStyle('cvc', '#input-field', inputStyles)
            this.form.setStyle('cvc', '#input-field::placeholder', inputPlaceholderStyles)
        });

        form.onChange(({ params: { scheme } }) => {
            this.scheme = scheme;
        });

        form.init({
            cardNumber: {
                container: 'number_container',
                placeholder: '1234 1234 1234 1234',
            },
            cvc: {
                container: 'cvc_container',
                placeholder: '123',
            }
        });

        this.form = form;
    },
    computed: {
        amountPreview() {
            return new Intl.NumberFormat(app.getBrowserLocale(), {
                style: 'currency',
                currency: this.currency,
            }).format(this.amount);
        },
        amountInMinorDigits() {
            const exponent = new Intl.NumberFormat(app.getBrowserLocale(), {
                style: 'currency',
                currency: this.currency,
            }).resolvedOptions().minimumFractionDigits;

            return this.amount * Math.pow(10, exponent);
        },
    },
    methods: {
        submit() {
            this.isLoading = true;
            this.isNumberValid = true;
            this.isCvcValid = true;
            this.error = '';

            this.form.validate().then(({ success, fields: { cardNumber, cvc } }) => {
                if (success) {
                    this.form.submit().then(
                        ({ card_number, cvc }) => {
                            this.authorize(card_number, cvc);
                        },
                        ({ error_details }) => {
                            this.error = error_details;
                        }
                    );
                } else {
                    this.isLoading = false;
                    this.isNumberValid = cardNumber;
                    this.isCvcValid = cvc;
                }
            });
        },
        authorize(cardToken, cvcToken) {
            app.callAjax({
                type: 'post',
                url: location.href,
                data: {
                    amount: this.amountInMinorDigits,
                    currency: this.currency,
                    merchant_reference: this.merchantReference,
                    description: this.description,
                    holder: this.holderName,
                    card_token: cardToken,
                    cvc_token: cvcToken,
                    expiry: this.expiryYear + '-' + this.expiryMonth.toString().padStart(2, '0'),
                },
                success: ({ transaction_id, receipt_url }) => {
                    this.transactionId = transaction_id;
                    this.transactionReceiptUrl = receipt_url;
                },
                complete: () => {
                    app.hideLoadingOverlay();
                    this.isLoading = false;
                },
            });
        },
        copyTransactionId() {
            navigator.clipboard.writeText(this.transactionId).then(() => {
                this.copiedTransactionId = true;
            });
        },
        newPayment() {
            app.showLoadingOverlay();
            location.reload();
        },
    }
}
</script>

<style>
#number_container {
    width         : 200px;
    height        : 40px;
    margin-bottom : 4px;
}

#cvc_container {
    width         : 60px;
    height        : 40px;
    margin-bottom : 4px;
}

#expiry_month {
    display : inline-block;
    width   : 140px;
}

#expiry_year {
    display : inline-block;
    width   : 80px;
}

#currency, #amount {
    display      : inline-block;
    width        : 80px;
    margin-right : 10px;
}

code.transaction-id {
    padding-right : 10px;
}

.help-block {
    padding : 0 !important;
}

.help-block p {
    font-size   : 14px;
    line-height : 16px;
}
</style>
