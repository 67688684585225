<script setup>

import {computed} from "vue";

const model = defineModel();
const props = defineProps({
    currencies: {
        type: Array,
        required: true
    },
    preferableCurrencies: {
        type: Array,
        default: [
            'CHF',
            'EUR',
            'GBP',
            'USD',
        ]
    }
});
const preferableOptions = computed(() => {
    return [...new Set(props.currencies)].filter(currency => props.preferableCurrencies.includes(currency));
});
const otherOptions = computed(() => {
    return [...new Set(props.currencies)].filter(currency => !props.preferableCurrencies.includes(currency));
});

</script>

<template>
    <select class="currency" v-model="model">
        <option v-for="option in preferableOptions" :value="option">{{ option }}</option>
        <option v-if="preferableOptions.length && otherOptions.length" disabled>──────────</option>
        <option v-for="option in otherOptions" :value="option">{{ option }}</option>
    </select>
</template>
