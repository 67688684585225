<script>
const regionRequiredTypes = ['card', 'credit_card', 'direct_debit', 'klarna', 'sofort', 'apple_pay', 'google_pay'];

export function regionName(value) {
    if (value === null) {
        return 'Default Region';
    }
    return value.charAt(0).toUpperCase() + value.slice(1);
}
</script>

<script setup>

import {computed} from "vue";

const model = defineModel();
const props = defineProps({
    regions: {
        type: Array,
        required: true
    },
    type: {
        type: [String, null],
    },
});

const options = computed(() => {
    const options = [null, ...props.regions].map((o) => ({value: o, label: regionName(o)}));

    if (regionRequiredTypes.includes(props.type)) {
        return options;
    }

    return options.filter((o) => o.value === null);
});

</script>

<template>
    <select v-model="model">
        <option v-for="option in options" :value="option.value">{{ option.label }}</option>
    </select>
</template>
